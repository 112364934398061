/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramItemDTO } from '../models/ProgramItemDTO';
import type { ProgramItemGetListResponse } from '../models/ProgramItemGetListResponse';
import type { ProgramItemSaveRequest } from '../models/ProgramItemSaveRequest';
import type { ProgramItemWebinarsSaveRequest } from '../models/ProgramItemWebinarsSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProgramItemService {
    /**
     * Получение списка элементов расписания.
     * @returns ProgramItemGetListResponse Список
     * @throws ApiError
     */
    public static getAppApiCalendarProgramitemcrudGetlist({
        programId,
        startAtFrom,
        startAtTo,
    }: {
        programId: string,
        startAtFrom?: string | null,
        startAtTo?: string | null,
    }): CancelablePromise<ProgramItemGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs/{programId}/program-items',
            path: {
                'programId': programId,
            },
            query: {
                'startAtFrom': startAtFrom,
                'startAtTo': startAtTo,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание элемента расписания.
     * @returns ProgramItemSaveRequest Созданный элемент
     * @throws ApiError
     */
    public static postAppApiCalendarProgramitemcrudActioncreate({
        programId,
        requestBody,
    }: {
        programId: string,
        /**
         * Данные
         */
        requestBody?: ProgramItemSaveRequest,
    }): CancelablePromise<ProgramItemSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/programs/{programId}/program-items',
            path: {
                'programId': programId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение элемента расписания по идентификатору.
     * @returns ProgramItemDTO Созданный ProgramItem
     * @throws ApiError
     */
    public static getAppApiCalendarProgramitemcrudGetbyid({
        id,
        programId,
    }: {
        /**
         * Идентификатор ProgramItem
         */
        id: number,
        programId: string,
    }): CancelablePromise<ProgramItemDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs/{programId}/program-items/{id}',
            path: {
                'id': id,
                'programId': programId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ProgramItem с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление элемента расписания.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiCalendarProgramitemcrudActiondelete({
        id,
        programId,
    }: {
        /**
         * Идентификатор
         */
        id: number,
        programId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/programs/{programId}/program-items/{id}',
            path: {
                'id': id,
                'programId': programId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Элемент расписания с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление элемента расписания.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiCalendarProgramitemcrudActionupdate({
        id,
        programId,
        requestBody,
    }: {
        /**
         * Идентификатор
         */
        id: number,
        programId: string,
        /**
         * Данные
         */
        requestBody?: ProgramItemSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/programs/{programId}/program-items/{id}',
            path: {
                'id': id,
                'programId': programId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ProgramItem с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Множественное создание вебинаров.
     * @returns void
     * @throws ApiError
     */
    public static postAppApiCalendarProgramitemcrudActioncreatewebinars({
        programId,
        requestBody,
    }: {
        programId: string,
        /**
         * Данные
         */
        requestBody?: ProgramItemWebinarsSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/programs/{programId}/program-items/webinars',
            path: {
                'programId': programId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
}
