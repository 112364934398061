/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TaskResultEnum {
    CORRECT = 'correct',
    PARTIALLY_CORRECT = 'partially_correct',
    CHECKING = 'checking',
    INCORRECT = 'incorrect',
    SKIPPED = 'skipped',
}
