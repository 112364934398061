/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StreamEducationDTO } from '../models/StreamEducationDTO';
import type { StreamEducationGetListResponse } from '../models/StreamEducationGetListResponse';
import type { StreamEducationSaveRequest } from '../models/StreamEducationSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StreamEducationService {
    /**
     * Получение списка потоков обучения.
     * @returns StreamEducationGetListResponse Список StreamEducationRepository
     * @throws ApiError
     */
    public static getAppApiCalendarStreameducationcrudGetlist({
        limit,
        offset,
        query,
        idsArray,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        idsArray?: Array<number>,
    }): CancelablePromise<StreamEducationGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/streams-education',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'ids[]': idsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание потока обучения.
     * @returns StreamEducationSaveRequest Созданный StreamEducationRepository
     * @throws ApiError
     */
    public static postAppApiCalendarStreameducationcrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные StreamEducationRepository
         */
        requestBody?: StreamEducationSaveRequest,
    }): CancelablePromise<StreamEducationSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/streams-education',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение потока обучения по идентификатору.
     * @returns StreamEducationDTO Созданный StreamEducationRepository
     * @throws ApiError
     */
    public static getAppApiCalendarStreameducationcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор StreamEducationRepository
         */
        id: number,
    }): CancelablePromise<StreamEducationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/streams-education/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `StreamEducationRepository с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление потока обучения.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiCalendarStreameducationcrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор StreamEducationRepository
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/streams-education/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `StreamEducationRepository с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление потока обучения.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiCalendarStreameducationcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор StreamEducationRepository
         */
        id: number,
        /**
         * StreamEducationRepository
         */
        requestBody?: StreamEducationSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/streams-education/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `StreamEducationRepository с таким идентификатором не найден`,
            },
        });
    }
}
