/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LessonSubTopicTaskDTO } from '../models/LessonSubTopicTaskDTO';
import type { LessonSubTopicTaskGetListResponse } from '../models/LessonSubTopicTaskGetListResponse';
import type { LessonSubTopicTaskMultipleSaveRequest } from '../models/LessonSubTopicTaskMultipleSaveRequest';
import type { LessonSubTopicTaskOrderPositionSaveRequest } from '../models/LessonSubTopicTaskOrderPositionSaveRequest';
import type { LessonSubTopicTaskSaveRequest } from '../models/LessonSubTopicTaskSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LessonSubTopicTaskService {
    /**
     * Получение списка задач главы.
     * @returns LessonSubTopicTaskGetListResponse Список LessonSubTopicTask
     * @throws ApiError
     */
    public static getAppApiEducationLessonsubtopictaskcrudGetlist({
        subTopicId,
        query,
    }: {
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        query?: string | null,
    }): CancelablePromise<LessonSubTopicTaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks',
            path: {
                'subTopicId': subTopicId,
            },
            query: {
                'query': query,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание задачи главы.
     * @returns LessonSubTopicTaskSaveRequest Созданный LessonSubTopicTask
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopictaskcrudActioncreate({
        subTopicId,
        requestBody,
    }: {
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        /**
         * Данные LessonSubTopicTask
         */
        requestBody?: LessonSubTopicTaskSaveRequest,
    }): CancelablePromise<LessonSubTopicTaskSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks',
            path: {
                'subTopicId': subTopicId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение задачи главы по идентификатору.
     * @returns LessonSubTopicTaskDTO Созданный LessonSubTopicTask
     * @throws ApiError
     */
    public static getAppApiEducationLessonsubtopictaskcrudGetbyid({
        id,
        subTopicId,
    }: {
        /**
         * Идентификатор LessonSubTopicTask
         */
        id: number,
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
    }): CancelablePromise<LessonSubTopicTaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/{id}',
            path: {
                'id': id,
                'subTopicId': subTopicId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopicTask с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление задачи главы.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationLessonsubtopictaskcrudActiondelete({
        id,
        subTopicId,
    }: {
        /**
         * Идентификатор LessonSubTopicTask
         */
        id: number,
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/{id}',
            path: {
                'id': id,
                'subTopicId': subTopicId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача главы с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Обновление задачи главы.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationLessonsubtopictaskcrudActionupdate({
        id,
        subTopicId,
        requestBody,
    }: {
        /**
         * Идентификатор LessonSubTopicTask
         */
        id: number,
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        /**
         * LessonSubTopicTask
         */
        requestBody?: LessonSubTopicTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/{id}',
            path: {
                'id': id,
                'subTopicId': subTopicId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopicTask с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Множественное создание задач главы.
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopictaskcrudActioncreatemultiple({
        subTopicId,
        requestBody,
    }: {
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        /**
         * Данные LessonSubTopicTask
         */
        requestBody?: LessonSubTopicTaskMultipleSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/multiple',
            path: {
                'subTopicId': subTopicId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Установка видимости задачи главы
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopictaskcrudActionsetvisible({
        id,
        subTopicId,
        value,
    }: {
        /**
         * Идентификатор задачи главы
         */
        id: number,
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        value: 'hide' | 'show',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/{id}/{value}',
            path: {
                'id': id,
                'subTopicId': subTopicId,
                'value': value,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача главы с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Установка порядкового номера
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopictaskcrudActionsetorderposition({
        id,
        subTopicId,
        requestBody,
    }: {
        /**
         * Идентификатор LessonSubTopicTask
         */
        id: number,
        /**
         * Идентификатор главы урока
         */
        subTopicId: number,
        /**
         * Данные для изменения порядкового номера
         */
        requestBody?: LessonSubTopicTaskOrderPositionSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lesson-sub-topics/{subTopicId}/tasks/{id}/order-position',
            path: {
                'id': id,
                'subTopicId': subTopicId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopicTask с таким идентификатором не найден`,
            },
        });
    }
}
