/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeDeadlineRequest } from '../models/ChangeDeadlineRequest';
import type { SendIndividualTestRequest } from '../models/SendIndividualTestRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class IndividualTestResultsService {
    /**
     * Выдача индивидуального теста
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationIndividualtestActionsend({
        requestBody,
    }: {
        /**
         * Данные для выдачи теста
         */
        requestBody?: SendIndividualTestRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/individual',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Изменение дедлайна выданного теста
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationIndividualtestActionchangedeadline({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Данные для выдачи теста
         */
        requestBody?: ChangeDeadlineRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/individual/{id}/deadline',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Удаление выданного теста
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationIndividualtestActiondelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tests/individual/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
}
