/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileInfoDTO } from '../models/FileInfoDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FilesService {
    /**
     * @returns any
     * @throws ApiError
     */
    public static postAppApiFilesuploaderTinyeditoruploaderTinyeditorupload(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tiny/upload/image',
        });
    }
    /**
     * @returns FileInfoDTO Информация о файле
     * @throws ApiError
     */
    public static postAppApiFilesuploaderUploaderUploadadmin(): CancelablePromise<FileInfoDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/admin/files',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiFilesuploaderUploaderActiondelete(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/admin/files',
        });
    }
    /**
     * Импорт видео по ссылке
     * @returns FileInfoDTO Информация о файле
     * @throws ApiError
     */
    public static postAppApiFilesuploaderUploaderImportvideoadmin({
        url,
    }: {
        /**
         * Ссылка на видео
         */
        url: any,
    }): CancelablePromise<FileInfoDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/admin/files/import-video',
            query: {
                'url': url,
            },
        });
    }
    /**
     * @returns FileInfoDTO Информация о файле
     * @throws ApiError
     */
    public static postAppApiFilesuploaderUploaderUploadclient(): CancelablePromise<FileInfoDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/client/files',
        });
    }
    /**
     * @returns FileInfoDTO Информация о файлах
     * @throws ApiError
     */
    public static getAppApiFilesuploaderUploaderGetbyids({
        ids,
    }: {
        /**
         * Идентификаторы файлов
         */
        ids: Array<number>,
    }): CancelablePromise<Array<FileInfoDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files',
            query: {
                'ids': ids,
            },
        });
    }
}
