import * as React from 'react';
import Button from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {useEffect, useMemo, useState} from "react";
import {styled} from "@mui/material/styles";
import {p11n} from "../../helpers/string";

interface IScoreRatingButtonProps {
    initialScore: number | null | undefined
    maxScore: number | null | undefined
    onScoreChange: (value: number) => void
}

const ButtonGroup = styled(MuiButtonGroup)({
    boxShadow: 'none',
    'button': {
        boxShadow: 'none',
        color: 'white',
    },
    'button:hover': {
        boxShadow: 'none',
        color: 'white',
    },
    '.MuiButton-containedSecondary': {
        backgroundColor: 'rgba(38, 51, 64, 1)',
        borderColor: 'rgba(19, 25, 32, 1) !important',
    },
    '.MuiButton-containedSecondary:hover': {
        backgroundColor: 'rgba(19, 25, 32, 1)',
        borderColor: 'rgba(19, 25, 32, 1) !important',
    },
    '.MuiButton-containedError': {
        backgroundColor: 'rgba(255, 8, 41, 1)',
        borderColor: 'rgba(197, 27, 27, 1) !important',
    },
    '.MuiButton-containedError:hover': {
        backgroundColor: 'rgb(218,24,50)',
        borderColor: 'rgba(197, 27, 27, 1) !important',
    },
    '.MuiButton-containedPrimary': {
        color: 'rgba(38, 51, 64, 1)',
        backgroundColor: 'rgba(233, 237, 242, 1)',
        borderColor: 'rgba(191, 204, 217, 1) !important',
    },
    '.MuiButton-containedPrimary:hover': {
        color: 'white',
        backgroundColor: 'rgba(38, 51, 64, 1)',
        borderColor: 'rgba(233, 237, 242, 1) !important',
    },
    '.Mui-disabled': {
        backgroundColor: 'rgba(38, 51, 64, 1) !important',
        color: 'white !important',
    }
})

const ScoreRatingButton: React.FC<IScoreRatingButtonProps> = (props) => {
    const {maxScore, initialScore, onScoreChange } = props
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(initialScore);
    const [score, setScore] = useState<number | null | undefined>(initialScore)

    useEffect(() => {
        setScore(initialScore)
        setSelectedIndex(initialScore)
    }, [initialScore])

    useEffect(() => {
        if (typeof score === 'number') {
            onScoreChange(score)
        }
    }, [score])

    const options = useMemo(() => {
        const result = []
        if (typeof maxScore === 'number') {
            for (let i = 0; i <= maxScore; i++) {
                result.push({
                    text: `${i} ${p11n(i, 'балл', 'балла', 'баллов')}`,
                    value: i
                })
            }
        }

        return result
    }, [maxScore])

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex || 0]}`);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
        option: {
            text: string
            value: number
        }
    ) => {
        setScore(option.value)
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const buttonText = useMemo(() => {
        if (typeof score === 'number') {
            return `${score} ${p11n(score, 'балл', 'балла', 'баллов')} из ${maxScore}`
        }

        return `нет оценки`
    }, [score, maxScore])

    if (options.length <= 10) {
        return (
            <React.Fragment>
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    color={typeof score === 'number' ? 'primary' : 'error'}
                    style={{borderRadius: '8px', overflow: 'hidden'}}
                >
                    {options.map((item, key) => {
                        return (
                            <Button
                                size={'small'}
                                disabled={selectedIndex === key}
                                style={{padding: '8px 22px'}}
                                key={key}
                                onClick={(event: any) => {
                                    handleMenuItemClick(event, key, item)
                                }}
                            >
                                {key}
                            </Button>
                        )
                    })}
                </ButtonGroup>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                color={typeof score === 'number' ? 'secondary' : 'error'}
            >
                <Button size={'small'} onClick={handleClick} style={{minWidth: '192px'}}>
                    {buttonText}
                </Button>

                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{
                        padding: '10px'
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Popper
                sx={{ zIndex: 10, width: anchorRef?.current?.offsetWidth || undefined }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'bottom'}
            >
                {({ TransitionProps, placement }) => {
                    return (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                maxHeight: '376px',
                                position: 'relative',
                                zIndex: 100,
                                margin: '8px 0',
                                overflow: 'auto'
                            }}
                        >
                            <Paper sx={{overflow: 'auto', borderRadius: '8px'}}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index, option)}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )
                }}
            </Popper>
        </React.Fragment>
    );
}

export default React.memo(ScoreRatingButton);