import { stringToColor } from '../../../../helpers/stringToColor'

interface IStringAvatar {
    (name?: string, options?: {
        width?: number
        height?: number
        fontSize?: string
        fontWeight?: string | number
    }): {
        sx: Record<string, any>,
        children: string
    }
}

export const stringAvatar: IStringAvatar = (name = 'Тест', options) => {
    const arr = name.split(' ')

    const firstLetter = arr[0] ? arr[0][0] : ''
    const secondLetter = arr[1] ? arr[1][0] : ''

    return {
        sx: {
            bgcolor: stringToColor(name),
            width: options?.width || 16,
            height: options?.height || 16,
            fontSize: options?.fontSize || '8px',
            fontWeight: options?.fontWeight || 600
        },
        children: `${firstLetter}${secondLetter}`,
    };
}