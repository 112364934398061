/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationTestDTO } from '../models/EducationTestDTO';
import type { EducationTestForSelectDTO } from '../models/EducationTestForSelectDTO';
import type { EducationTestGetListResponse } from '../models/EducationTestGetListResponse';
import type { EducationTestGetStatsResponse } from '../models/EducationTestGetStatsResponse';
import type { EducationTestSaveRequest } from '../models/EducationTestSaveRequest';
import type { EducationTestStatusEnum } from '../models/EducationTestStatusEnum';
import type { EducationTestTypeEnum } from '../models/EducationTestTypeEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EducationTestService {
    /**
     * Получение списка EducationTest.
     * @returns EducationTestGetListResponse Список EducationTest
     * @throws ApiError
     */
    public static getAppApiEducationEducationtestcrudGetlist({
        limit,
        offset,
        query,
        subjectIdsArray,
        type,
        classesArray,
        isPublished,
        topicIdsArray,
        examTypeIdsArray,
        statusesArray,
        isDemo,
        isPracticeExam,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        type?: EducationTestTypeEnum | null,
        classesArray?: Array<number>,
        isPublished?: boolean | null,
        topicIdsArray?: Array<number>,
        examTypeIdsArray?: Array<number>,
        statusesArray?: Array<EducationTestStatusEnum>,
        isDemo?: boolean | null,
        isPracticeExam?: boolean | null,
    }): CancelablePromise<EducationTestGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'type': type,
                'classes[]': classesArray,
                'isPublished': isPublished,
                'topicIds[]': topicIdsArray,
                'examTypeIds[]': examTypeIdsArray,
                'statuses[]': statusesArray,
                'isDemo': isDemo,
                'isPracticeExam': isPracticeExam,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание EducationTest.
     * @returns EducationTestSaveRequest Созданный EducationTest
     * @throws ApiError
     */
    public static postAppApiEducationEducationtestcrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные EducationTest
         */
        requestBody?: EducationTestSaveRequest,
    }): CancelablePromise<EducationTestSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение списка тестов для выбора.
     * @returns EducationTestForSelectDTO Список Employee
     * @throws ApiError
     */
    public static getAppApiEducationEducationtestcrudGetlistforselect({
        idsArray,
        query,
        subjectIdsArray,
        type,
        classesArray,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        type?: EducationTestTypeEnum | null,
        classesArray?: Array<number>,
    }): CancelablePromise<Array<EducationTestForSelectDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests/for-select',
            query: {
                'ids[]': idsArray,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'type': type,
                'classes[]': classesArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение EducationTest по идентификатору.
     * @returns EducationTestDTO Созданный EducationTest
     * @throws ApiError
     */
    public static getAppApiEducationEducationtestcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
    }): CancelablePromise<EducationTestDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление EducationTest.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationEducationtestcrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tests/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление EducationTest.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationEducationtestcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
        /**
         * EducationTest
         */
        requestBody?: EducationTestSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/tests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Отправка в архив
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationEducationtestcrudActionarchive({
        id,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Публикация
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationEducationtestcrudActionpublish({
        id,
    }: {
        /**
         * Идентификатор EducationTest
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{id}/publish',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTest с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Получение статистики выполняемости тестов
     * @returns EducationTestGetStatsResponse Статистика выполняемости тестов
     * @throws ApiError
     */
    public static getAppApiEducationEducationtestcrudGetstats({
        idsArray,
    }: {
        idsArray?: Array<number>,
    }): CancelablePromise<EducationTestGetStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests/stats',
            query: {
                'ids[]': idsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
}
