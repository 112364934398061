import Box from "@mui/material/Box";
import * as React from "react";
import {Divider, Drawer, Grid, ListItemIcon, ListItemText, MenuItem, useMediaQuery} from "@mui/material";
import {Link, Outlet, useLocation} from "react-router-dom";
import {FC, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {ReactComponent as IconUcheba} from './assets/logo-ucheba-vector.svg'
import {ReactComponent as IconUchebaFull} from './assets/logo-ucheba-vector_full.svg'
import NavItem from "../../components/custom/NavItem";
import styles from './styles.module.css'
import Navigation from "../../components/custom/Navigation";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MoreButton from "../../components/custom/MoreButton";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {SubjectDTO} from "../../generated/api";
import {ProfileContext} from "../../App";
import {logout} from "../../helpers/logout";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import TelegramIcon from '@mui/icons-material/Telegram';
import TgAlertProvider from "../../components/wrappers/TgAlertProvider";

const Layout: FC = () => {
    const [isFull, setIsFull] = useState(false)
    const refSidebar = useRef<HTMLElement | null>(null)
    const {pathname} = useLocation()
    const [morePositionLeft, setMorePositionLeft] = useState<number | undefined>(undefined)
    const [morePositionTop, setMorePositionTop] = useState<number | undefined>(undefined)
    const [selectedSubject, setSelectedSubject] = useState<SubjectDTO>();
    const changeSubjectHandler = useCallback((subject: SubjectDTO) => {
        setSelectedSubject(subject);
        localStorage.setItem('selectedSubjectId', subject.id?.toString() || '');
    }, [setSelectedSubject]);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = useCallback(() => {
        setMobileOpen(!mobileOpen);
    }, [setMobileOpen, mobileOpen])

    useEffect(() => {
        setMobileOpen(false)
    }, [pathname])

    useEffect(() => {
        if (refSidebar.current) {
            const left = refSidebar.current?.offsetWidth
            const top = refSidebar.current?.offsetHeight

            if (left) {
                setMorePositionLeft(left + 32)
                setMorePositionTop(top - 87.8)
            }
        }
    }, [])

    const onHandleResize = useCallback(() => {
        if (window.innerWidth >= 1366) {
            setIsFull(true)
        } else {
            setIsFull(false)
        }
    },[])

    useLayoutEffect(() => {
        onHandleResize()
        window.addEventListener('resize', onHandleResize)

        return () => {
            window.removeEventListener('resize', onHandleResize)
        }
    }, [])

    const profile = useContext(ProfileContext)

    return (
        <Grid container sx={{minWidth: '1024px'}}>
            <Grid className={styles.sidebar__root__mobile} item width={'100%'}>
                <Box
                    padding={'12px'}
                    bgcolor={'#263340'}
                    width={'100%'}
                    top={0}
                    left={0}
                >
                    <IconButton onClick={handleDrawerToggle}>
                        <MenuIcon sx={{color: 'white'}}/>
                    </IconButton>

                    <Drawer
                        sx={{
                            height: '100vh',
                            '.MuiDrawer-paper, .MuiBackdrop-root': {
                                maxHeight: '100vh'
                            }
                        }}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        <Box
                            ref={refSidebar}
                            borderRadius={'0px'}
                            bgcolor={'#263340'}
                            color={'white'}
                            padding={'8px'}
                            height={'100vh'}
                            maxWidth={'70vw'}
                            overflow={'auto'}
                        >
                            <Grid container rowSpacing={1} height={'100%'}>
                                <Grid item xs={12}>
                                    <Box padding={'8px'} width={'100%'}>
                                        <div style={{
                                            padding: '8px 0'
                                        }}>
                                            <Link to={'/'}>
                                                <IconUchebaFull />
                                            </Link>
                                        </div>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box padding={'8px'} width={'100%'}>
                                        <Navigation isFull limit={99} />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
                                    <Box padding={'8px'} width={'100%'}>
                                        <Box padding={'20px 20px 0 20px'}>
                                            <Typography color={'white'} fontSize={'14px'} lineHeight={'21px'} fontWeight={700} style={{
                                                marginBottom: '16px'
                                            }}>
                                                {profile?.name || ''}
                                            </Typography>
                                        </Box>

                                        <Divider sx={{backgroundColor: 'rgba(255, 255, 255, 0.08)'}} />

                                        <Box>
                                            <MenuList style={{padding: 0}}>
                                                <MenuItem
                                                    style={{
                                                        height: '48px'
                                                    }}
                                                >
                                                    <ListItemIcon color={'white'}>
                                                        <WhatsAppIcon sx={{color: 'white'}} />
                                                    </ListItemIcon>

                                                    <ListItemText style={{color: 'white'}}>
                                                        <NavItem
                                                            text="Поддержка"
                                                            target={'_blank'}
                                                            href={'https://api.whatsapp.com/send/?phone=79169949316&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D1%82%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%3A'}
                                                        />
                                                    </ListItemText>
                                                </MenuItem>

                                                <MenuItem
                                                    style={{
                                                        height: '48px'
                                                    }}
                                                >
                                                    <ListItemIcon color={'white'}>
                                                        <SettingsIcon sx={{color: 'white'}} />
                                                    </ListItemIcon>

                                                    <ListItemText style={{color: 'white'}}>
                                                        <NavItem text="Настройки" href={'/settings'} />
                                                    </ListItemText>
                                                </MenuItem>

                                                <MenuItem
                                                    style={{
                                                        height: '48px'
                                                    }}
                                                    onClick={() => {
                                                        logout()
                                                    }}
                                                >
                                                    <ListItemIcon color={'white'}>
                                                        <LogoutIcon sx={{color: 'white'}} />
                                                    </ListItemIcon>

                                                    <ListItemText sx={{color: 'white !important'}}>
                                                        <NavItem text="Выход" />
                                                    </ListItemText>
                                                </MenuItem>
                                            </MenuList>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Drawer>
                </Box>
            </Grid>

            <Grid
                className={styles.sidebar__root}
                item
                xs={'auto'}
                position={'relative'}
            >
                <Box
                    padding={'24px 0 24px 24px'}
                    height={'100%'}
                >
                    <div className={styles.Layout__block__sidebar} />

                    <Box
                        ref={refSidebar}
                        className={styles.sidebar__box}
                        borderRadius={'12px'}
                        bgcolor={'#263340'}
                        color={'white'}
                        padding={'0px'}
                    >
                        <Grid container rowSpacing={1} height={'100%'}>
                            <Grid item xs={12}>
                                <Box padding={'16px'} width={'100%'}>
                                    {!isFull && <NavItem IconElement={IconUcheba} href={'/'} />}

                                    {isFull && (
                                        <div style={{
                                            padding: '8px 0'
                                        }}>
                                            <Link to={'/'}>
                                                <IconUchebaFull />
                                            </Link>
                                        </div>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} alignSelf='center'>
                                <Box padding={'16px'} width={'100%'}>
                                    <Navigation isFull={isFull} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
                                <Box padding={'16px 0 16px 16px'} width={'100%'}>
                                    <NavItem
                                        target={'_blank'}
                                        href={'https://api.whatsapp.com/send/?phone=79169949316&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D1%82%D0%B0%D0%BA%D0%BE%D0%B9%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%3A'}
                                        Icon={<WhatsAppIcon sx={{color: 'white'}} />} text={isFull ? 'Поддержка' : undefined}
                                    />

                                    <MoreButton
                                        positionLeft={morePositionLeft}
                                        positionTop={morePositionTop}
                                        content={() => {
                                            return (
                                                <Paper
                                                    style={{
                                                        padding: '8px 0',
                                                        width: '161px',
                                                        backgroundColor: '#263340',
                                                        borderRadius: '16px',
                                                }}>
                                                    <Box>
                                                        <MenuList style={{padding: 0}}>
                                                            <MenuItem
                                                                style={{
                                                                    height: '48px'
                                                                }}
                                                            >
                                                                <ListItemIcon color={'white'}>
                                                                    <SettingsIcon sx={{color: 'white'}} />
                                                                </ListItemIcon>

                                                                <ListItemText style={{color: 'white'}}>
                                                                    <NavItem text="Настройки" href={'/settings'} />
                                                                </ListItemText>
                                                            </MenuItem>

                                                            <MenuItem
                                                                style={{
                                                                    height: '48px'
                                                                }}
                                                                onClick={() => {
                                                                    logout()
                                                                }}
                                                            >
                                                                <ListItemIcon color={'white'}>
                                                                    <LogoutIcon sx={{color: 'white'}} />
                                                                </ListItemIcon>

                                                                <ListItemText sx={{color: 'white !important'}}>
                                                                    <NavItem text="Выход" />
                                                                </ListItemText>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Box>
                                                </Paper>
                                            )
                                        }}>

                                        <Box sx={{
                                            overflow: 'hidden',
                                            position: 'relative',
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: '13px',
                                                right:'0',
                                                width: '16px',
                                                height: '20px',
                                                background: 'linear-gradient(to left, #263340, rgba(38, 51, 64, 0))',
                                                pointerEvents: 'none',
                                            },
                                        }}>
                                            <NavItem withAvatar avatarUrl={profile?.avatar} text={isFull ? profile?.name || '' : undefined} />
                                        </Box>
                                    </MoreButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>

            <Grid
                minHeight={'100%'}
                item
                padding={'24px'}
                xs
                zeroMinWidth
            >
                <TgAlertProvider>
                    <Outlet />
                </TgAlertProvider>
            </Grid>
        </Grid>
    )
}

export default memo(Layout)