/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDTO } from '../models/EmployeeDTO';
import type { ImportUserByTokenRequest } from '../models/ImportUserByTokenRequest';
import type { ImportUserRequest } from '../models/ImportUserRequest';
import type { StudentDTO } from '../models/StudentDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersImportService {
    /**
     * @returns EmployeeDTO Добавленный пользователь
     * @throws ApiError
     */
    public static postAppApiUserImportActionimportemployee({
        requestBody,
    }: {
        /**
         * Информация для импорта
         */
        requestBody?: ImportUserRequest,
    }): CancelablePromise<EmployeeDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/import/employee',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
    /**
     * @returns StudentDTO Добавленный пользователь
     * @throws ApiError
     */
    public static postAppApiUserImportActionimportstudent({
        requestBody,
    }: {
        /**
         * Информация для импорта
         */
        requestBody?: ImportUserRequest,
    }): CancelablePromise<StudentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/import/student',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
    /**
     * @returns StudentDTO Добавленный пользователь
     * @throws ApiError
     */
    public static postAppApiUserImportActionimportstudentbytoken({
        requestBody,
    }: {
        /**
         * Информация для импорта
         */
        requestBody?: ImportUserByTokenRequest,
    }): CancelablePromise<StudentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/import/student-by-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
}
