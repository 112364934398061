/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseProfileDTO } from '../models/BaseProfileDTO';
import type { CabinetDTO } from '../models/CabinetDTO';
import type { CreateCabinetRequest } from '../models/CreateCabinetRequest';
import type { UpdateCabinetRequest } from '../models/UpdateCabinetRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProfileService {
    /**
     * @returns BaseProfileDTO Профиль пользователя
     * @throws ApiError
     */
    public static getAppApiUserProfileActiongetprofile(): CancelablePromise<BaseProfileDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/profile',
        });
    }
    /**
     * Создание кабинета индивидуального преподавателя
     * @returns CabinetDTO Созданный кабинет
     * @throws ApiError
     */
    public static postAppApiUserProfileActioncreatecabinet({
        requestBody,
    }: {
        /**
         * Данные кабинета
         */
        requestBody?: CreateCabinetRequest,
    }): CancelablePromise<CabinetDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/profile/cabinet',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
    /**
     * Изменение кабинета
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiUserProfileActionpatchcabinet({
        requestBody,
    }: {
        /**
         * Данные кабинета
         */
        requestBody?: UpdateCabinetRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/profile/cabinet',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
}
