import {useCallback, useEffect, useMemo} from "react";
import {ApiError, EmployeeProfileDTO, OpenAPI, ProfileService} from "../generated/api";
import * as React from "react";
import getToken from "./getToken";

const redirectToAuth = () => {
  window.location.href = process.env.REACT_APP_AUTH_URL + '?type=teachers&redirect=' + encodeURIComponent(window.location.href);
}

export default function useProfile() {
  const [profile, setProfile] = React.useState<EmployeeProfileDTO>();
  const [isLoading, setIsLoading] = React.useState(true);
  const redirectToOnboarding = useCallback(() => {
    if (window.location.pathname !== '/onboarding') {
      window.location.href = '/onboarding';
    }
  }, [])

  const token = useMemo(() => {
    return getToken()
  }, []);

  useEffect(() => {
    if (!token) {
      redirectToAuth()
      return;
    } else {
      OpenAPI.TOKEN = token;
      ProfileService.getAppApiUserProfileActiongetprofile().then((res) => {
        if (!('rights' in res)) {
          setIsLoading(false)
          redirectToOnboarding();
          return
        }
        setProfile(res)
        setIsLoading(false)
      }).catch((err: ApiError) => {
        setIsLoading(false)
        if (err.status === 401) {
          redirectToOnboarding()
        } else {
          throw err;
        }
      })
    }
  }, [])
  return {profile, isLoading};
}