import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Stack,
} from "@mui/material";
import React, {useCallback, useEffect, useRef} from "react";
import localization from './localization'
import 'mathlive/fonts.css'
import {MathfieldElement, convertLatexToMathMl, convertLatexToMarkup} from "mathlive";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
    }
  }
}
document.body.style.setProperty("--keyboard-zindex", "3000");
MathfieldElement.strings = { ...MathfieldElement.strings, 'ru': localization }

interface MathEditorDialogProps {
  initialValue?: string,
  open: boolean,
  onClose: () => void,
  onInsert: (formula: MathFormulaResult) => void,
}

export interface MathFormulaResult {
  latex: string,
  mathMl: string,
  markup: string,
}

export default function MathEditorDialog(props: MathEditorDialogProps)
{
  const {
    open,
    initialValue,
    onClose,
    onInsert
  } = props

  const [value, setValue] = React.useState<string>(initialValue || '')
  const field = useRef<MathfieldElement>()

  const onInsertClick = useCallback(() => {
    onInsert({
      latex: value,
      mathMl: convertLatexToMathMl(value),
      markup: convertLatexToMarkup(value)
    })
  }, [onInsert, value])

  useEffect(() => {
  }, []);

  return (
      <Dialog fullWidth open={open} maxWidth='md'>
        <DialogTitle>{"Редактор формул"}</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack sx={{mt: 3}} spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* @ts-ignore */}
              <math-field ref={field} onInput={evt => setValue(evt.target.value)}
                          style={{width: '100%', fontSize: '1.5em', borderRadius: '8px'}}
              >
                {value}
              </math-field>
              <Button disabled={!value} onClick={onInsertClick} variant="contained">{initialValue ? 'Сохранить' : 'Вставить'}</Button>
            </Stack>
            <TextField
                label="LaTeX"
                multiline
                fullWidth
                rows={3}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
          </Stack>
        </DialogContent>
      </Dialog>
  )
}