/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExamTypeDTO } from '../models/ExamTypeDTO';
import type { ExamTypeGetListResponse } from '../models/ExamTypeGetListResponse';
import type { ExamTypeSaveRequest } from '../models/ExamTypeSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExamTypeService {
    /**
     * Получение списка ExamType.
     * @returns ExamTypeGetListResponse Список ExamType
     * @throws ApiError
     */
    public static getAppApiReferencesExamtypecrudGetlist({
        idsArray,
        limit,
        offset,
        query,
    }: {
        idsArray?: Array<number>,
        limit?: number,
        offset?: number,
        query?: string | null,
    }): CancelablePromise<ExamTypeGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exam-types',
            query: {
                'ids[]': idsArray,
                'limit': limit,
                'offset': offset,
                'query': query,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание ExamType.
     * @returns ExamTypeSaveRequest Созданный ExamType
     * @throws ApiError
     */
    public static postAppApiReferencesExamtypecrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные ExamType
         */
        requestBody?: ExamTypeSaveRequest,
    }): CancelablePromise<ExamTypeSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/exam-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение ExamType по идентификатору.
     * @returns ExamTypeDTO Созданный ExamType
     * @throws ApiError
     */
    public static getAppApiReferencesExamtypecrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор ExamType
         */
        id: number,
    }): CancelablePromise<ExamTypeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exam-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamType с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление ExamType.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiReferencesExamtypecrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор ExamType
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/exam-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamType с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление ExamType.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiReferencesExamtypecrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор ExamType
         */
        id: number,
        /**
         * ExamType
         */
        requestBody?: ExamTypeSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/exam-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamType с таким идентификатором не найден`,
            },
        });
    }
}
