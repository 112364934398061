/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationTestTaskDTO } from '../models/EducationTestTaskDTO';
import type { EducationTestTaskGetListResponse } from '../models/EducationTestTaskGetListResponse';
import type { EducationTestTaskOrderPositionSaveRequest } from '../models/EducationTestTaskOrderPositionSaveRequest';
import type { EducationTestTaskSaveRequest } from '../models/EducationTestTaskSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EducationTestTaskService {
    /**
     * Получение списка EducationTestTask.
     * @returns EducationTestTaskGetListResponse Список EducationTestTask
     * @throws ApiError
     */
    public static getAppApiEducationEducationtesttaskcrudGetlist({
        testId,
        limit,
        offset,
    }: {
        /**
         * Идентификатор теста
         */
        testId: number,
        limit?: number,
        offset?: number,
    }): CancelablePromise<EducationTestTaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests/{testId}/tasks',
            path: {
                'testId': testId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание EducationTestTask.
     * @returns EducationTestTaskSaveRequest Созданный EducationTestTask
     * @throws ApiError
     */
    public static postAppApiEducationEducationtesttaskcrudActioncreate({
        testId,
        requestBody,
    }: {
        /**
         * Идентификатор теста
         */
        testId: number,
        /**
         * Данные EducationTestTask
         */
        requestBody?: EducationTestTaskSaveRequest,
    }): CancelablePromise<EducationTestTaskSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{testId}/tasks',
            path: {
                'testId': testId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение EducationTestTask по идентификатору.
     * @returns EducationTestTaskDTO Созданный EducationTestTask
     * @throws ApiError
     */
    public static getAppApiEducationEducationtesttaskcrudGetbyid({
        id,
        testId,
    }: {
        /**
         * Идентификатор EducationTestTask
         */
        id: number,
        /**
         * Идентификатор теста
         */
        testId: number,
    }): CancelablePromise<EducationTestTaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tests/{testId}/tasks/{id}',
            path: {
                'id': id,
                'testId': testId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTestTask с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление EducationTestTask.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationEducationtesttaskcrudActiondelete({
        id,
        testId,
    }: {
        /**
         * Идентификатор EducationTestTask
         */
        id: number,
        /**
         * Идентификатор теста
         */
        testId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tests/{testId}/tasks/{id}',
            path: {
                'id': id,
                'testId': testId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTestTask с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление EducationTestTask.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationEducationtesttaskcrudActionupdate({
        id,
        testId,
        requestBody,
    }: {
        /**
         * Идентификатор EducationTestTask
         */
        id: number,
        /**
         * Идентификатор теста
         */
        testId: number,
        /**
         * EducationTestTask
         */
        requestBody?: EducationTestTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/tests/{testId}/tasks/{id}',
            path: {
                'id': id,
                'testId': testId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EducationTestTask с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Множественное создание EducationTestTask.
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationEducationtesttaskcrudActioncreatemultiple({
        testId,
        requestBody,
    }: {
        /**
         * Идентификатор теста
         */
        testId: number,
        /**
         * Данные EducationTestTask
         */
        requestBody?: EducationTestTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{testId}/tasks/multiple',
            path: {
                'testId': testId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Установка видимости задачи теста
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationEducationtesttaskcrudActionsetvisible({
        id,
        testId,
        value,
    }: {
        /**
         * Идентификатор задачи теста
         */
        id: number,
        /**
         * Идентификатор теста
         */
        testId: number,
        value: 'hide' | 'show',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{testId}/tasks/{id}/{value}',
            path: {
                'id': id,
                'testId': testId,
                'value': value,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача теста с таким идентификатором не найдена`,
            },
        });
    }
    /**
     * Установка порядкового номера
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationEducationtesttaskcrudActionsetorderposition({
        id,
        testId,
        requestBody,
    }: {
        /**
         * Идентификатор EducationTestTask
         */
        id: number,
        /**
         * Идентификатор теста
         */
        testId: number,
        /**
         * Данные для изменения порядкового номера
         */
        requestBody?: EducationTestTaskOrderPositionSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tests/{testId}/tasks/{id}/order-position',
            path: {
                'id': id,
                'testId': testId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopicTask с таким идентификатором не найден`,
            },
        });
    }
}
