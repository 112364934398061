import getToken from "./getToken";
import {setCookie} from "./setCookie";

export const logout = () => {
    const token = getToken()

    if (token) {
        const host = window.location.host
        const path = '/auth'
        let portalHost = ''

        if (host.includes('dev.ucheba.space')) {
            portalHost = 'https://dev.ucheba.space'
        } else if (host.includes('dev2.ucheba.space')) {
            portalHost = 'https://dev2.ucheba.space'
        } else if (host.includes('localhost')) {
            portalHost = 'http://localhost:3002'
        } else {
            portalHost = 'https://ucheba.ru'
        }

        // удаляем куку
        setCookie('uchru_token', token, { expires: -1, domain: process.env.REACT_APP_COOKIE_DOMAIN })

        const redirect = encodeURIComponent(window.location.href)

        window.location.href = `${portalHost}${path}?type=teachers&redirect=${redirect}`
    }
}