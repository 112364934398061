/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsWebinarsExistsResponse } from '../models/IsWebinarsExistsResponse';
import type { WebinarAttendanceResponse } from '../models/WebinarAttendanceResponse';
import type { WebinarDTO } from '../models/WebinarDTO';
import type { WebinarForSelectDTO } from '../models/WebinarForSelectDTO';
import type { WebinarGetListResponse } from '../models/WebinarGetListResponse';
import type { WebinarParticipantsResponse } from '../models/WebinarParticipantsResponse';
import type { WebinarSaveRequest } from '../models/WebinarSaveRequest';
import type { WebinarSessionCanceledRequest } from '../models/WebinarSessionCanceledRequest';
import type { WebinarSessionForSelectDTO } from '../models/WebinarSessionForSelectDTO';
import type { WebinarsSessionsGetListResponse } from '../models/WebinarsSessionsGetListResponse';
import type { WebinarTypeEnum } from '../models/WebinarTypeEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WebinarService {
    /**
     * Получение списка Webinar.
     * @returns WebinarGetListResponse Список Webinar
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudGetlist({
        limit,
        offset,
        query,
        subjectIdsArray,
        type,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        type?: WebinarTypeEnum | null,
    }): CancelablePromise<WebinarGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'type': type,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание Webinar.
     * @returns WebinarSaveRequest Созданный Webinar
     * @throws ApiError
     */
    public static postAppApiWebinarWebinarcrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные Webinar
         */
        requestBody?: WebinarSaveRequest,
    }): CancelablePromise<WebinarSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/webinars',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение списка вебинаров для выбора.
     * @returns WebinarForSelectDTO Список Webinars
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudGetwebinarlistforselect({
        idsArray,
        subjectIdsArray,
    }: {
        idsArray?: Array<number>,
        subjectIdsArray?: Array<number>,
    }): CancelablePromise<Array<WebinarForSelectDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/for-select',
            query: {
                'ids[]': idsArray,
                'subjectIds[]': subjectIdsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение Webinar по идентификатору.
     * @returns WebinarDTO Созданный Webinar
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор Webinar
         */
        id: string,
    }): CancelablePromise<WebinarDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Webinar с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление Webinar.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiWebinarWebinarcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор Webinar
         */
        id: string,
        /**
         * Webinar
         */
        requestBody?: WebinarSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/webinars/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Вебинар с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Проверка есть ли вебинары
     * @returns IsWebinarsExistsResponse Есть ли вебинары
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudActioniswebinarexists(): CancelablePromise<IsWebinarsExistsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/exists',
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Удаление WebinarSession.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiWebinarWebinarcrudActiondeletewebinarsession({
        webinarId,
        webinarSessionId,
    }: {
        /**
         * Идентификатор Webinar
         */
        webinarId: string,
        /**
         * Идентификатор WebinarSession
         */
        webinarSessionId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/webinars/{webinarId}/sessions/{webinarSessionId}',
            path: {
                'webinarId': webinarId,
                'webinarSessionId': webinarSessionId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `WebinarSession с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление Webinar.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiWebinarWebinarcrudActiondeletewebinar({
        webinarId,
    }: {
        /**
         * Идентификатор Webinar
         */
        webinarId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/webinars/{webinarId}',
            path: {
                'webinarId': webinarId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `WebinarSession с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление расписания.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiWebinarWebinarcrudActiondeletewebinarschedule({
        scheduleId,
    }: {
        /**
         * Идентификатор расписания
         */
        scheduleId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/webinars/schedules/{scheduleId}',
            path: {
                'scheduleId': scheduleId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Расписание с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Присвоение флага отмены сессии вебинара.
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiWebinarWebinarcrudActionsetcanceledwebinarsession({
        webinarId,
        sessionId,
        requestBody,
    }: {
        /**
         * Идентификатор Webinar
         */
        webinarId: string,
        /**
         * Идентификатор WebinarSession
         */
        sessionId: number,
        requestBody?: WebinarSessionCanceledRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/webinars/{webinarId}/sessions/{sessionId}/mark-is-canceled',
            path: {
                'webinarId': webinarId,
                'sessionId': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Сессия с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Получение списка WebinarsSession.
     * @returns WebinarsSessionsGetListResponse Список WebinarsSession
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudGetlistactionwebinarssessions({
        limit,
        offset,
        query,
        subjectIdsArray,
        teacherId,
        studentIdsArray,
        type,
        examTypeIdsArray,
        fromStartAtSession,
        toStartAtSession,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        teacherId?: number | null,
        studentIdsArray?: Array<number>,
        type?: WebinarTypeEnum | null,
        examTypeIdsArray?: Array<number>,
        fromStartAtSession?: string,
        toStartAtSession?: string,
    }): CancelablePromise<WebinarsSessionsGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/sessions',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'teacherId': teacherId,
                'studentIds[]': studentIdsArray,
                'type': type,
                'examTypeIds[]': examTypeIdsArray,
                'fromStartAtSession': fromStartAtSession,
                'toStartAtSession': toStartAtSession,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение списка сессий вебинаров для выбора.
     * @returns WebinarSessionForSelectDTO Список сессий вебинаров WebinarSessions
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarcrudGetwebinarssessionlistforselect({
        idsArray,
        subjectIdsArray,
        query,
        haveRecordId,
    }: {
        idsArray?: Array<number>,
        subjectIdsArray?: Array<number>,
        query?: string | null,
        haveRecordId?: boolean | null,
    }): CancelablePromise<Array<WebinarSessionForSelectDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/sessions/for-select',
            query: {
                'ids[]': idsArray,
                'subjectIds[]': subjectIdsArray,
                'query': query,
                'haveRecordId': haveRecordId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение посещаемости вебинаров
     * @returns WebinarAttendanceResponse Посещаемость по вебинарам
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarstatsGetwebinarattendance({
        idsArray,
    }: {
        idsArray?: Array<number>,
    }): CancelablePromise<WebinarAttendanceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/stats/attendance',
            query: {
                'ids[]': idsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение посещаемости вебинаров
     * @returns WebinarParticipantsResponse Участники вебинара
     * @throws ApiError
     */
    public static getAppApiWebinarWebinarstatsGetwebinarparticipants({
        id,
    }: {
        id: string,
    }): CancelablePromise<WebinarParticipantsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/webinars/{id}/participants',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
}
