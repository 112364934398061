/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeRoleDTO } from '../models/EmployeeRoleDTO';
import type { EmployeeRoleGetListResponse } from '../models/EmployeeRoleGetListResponse';
import type { EmployeeRoleSaveRequest } from '../models/EmployeeRoleSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeeRoleService {
    /**
     * Получение списка EmployeeRole.
     * @returns EmployeeRoleGetListResponse Список EmployeeRole
     * @throws ApiError
     */
    public static getAppApiUserEmployeerolecrudGetlist({
        idsArray,
        query,
        limit,
        offset,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        limit?: number,
        offset?: number,
    }): CancelablePromise<EmployeeRoleGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employee-roles',
            query: {
                'ids[]': idsArray,
                'query': query,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Создание EmployeeRole.
     * @returns EmployeeRoleSaveRequest Созданный EmployeeRole
     * @throws ApiError
     */
    public static postAppApiUserEmployeerolecrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные EmployeeRole
         */
        requestBody?: EmployeeRoleSaveRequest,
    }): CancelablePromise<EmployeeRoleSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/employee-roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }
    /**
     * Получение EmployeeRole по идентификатору.
     * @returns EmployeeRoleDTO Созданный EmployeeRole
     * @throws ApiError
     */
    public static getAppApiUserEmployeerolecrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор EmployeeRole
         */
        id: number,
    }): CancelablePromise<EmployeeRoleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employee-roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EmployeeRole с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Удаление EmployeeRole.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiUserEmployeerolecrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор EmployeeRole
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/employee-roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EmployeeRole с таким идентификатором не найден`,
            },
        });
    }
    /**
     * Обновление EmployeeRole.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiUserEmployeerolecrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор EmployeeRole
         */
        id: number,
        /**
         * EmployeeRole
         */
        requestBody?: EmployeeRoleSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/employee-roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `EmployeeRole с таким идентификатором не найден`,
            },
        });
    }
}
