import nookies from "nookies";

export const setCookie = (
    name: string,
    value: string,
    options: Record<string, any>,
): void => {
    if (options.expires && options.expires === -1) {
        nookies.destroy(null, name, { path: '/', ...options, expires: undefined })
    } else {
        nookies.set(null, name, value, { path: '/', ...options})
    }
}