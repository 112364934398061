/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubjectDTO } from '../models/SubjectDTO';
import type { SubjectGetListResponse } from '../models/SubjectGetListResponse';
import type { SubjectSaveRequest } from '../models/SubjectSaveRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubjectsService {
    /**
     * Получение списка предметов
     * @returns SubjectGetListResponse Список предметов
     * @throws ApiError
     */
    public static getAppApiReferencesSubjectcrudGetlist({
        idsArray,
        query,
        withoutRestriction,
        offset,
        limit,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        withoutRestriction?: boolean | null,
        offset?: number | null,
        limit?: number | null,
    }): CancelablePromise<SubjectGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects',
            query: {
                'ids[]': idsArray,
                'query': query,
                'withoutRestriction': withoutRestriction,
                'offset': offset,
                'limit': limit,
            },
        });
    }
    /**
     * Создание предмета
     * @returns SubjectDTO Созданный предмет
     * @throws ApiError
     */
    public static postAppApiReferencesSubjectcrudCreate({
        requestBody,
    }: {
        /**
         * Предмет
         */
        requestBody?: SubjectSaveRequest,
    }): CancelablePromise<SubjectDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subjects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }
    /**
     * Получение предмета по id
     * @returns SubjectDTO Созданный предмет
     * @throws ApiError
     */
    public static getAppApiReferencesSubjectcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор предмета
         */
        id: number,
    }): CancelablePromise<SubjectDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Предмет с таким ID не найден`,
            },
        });
    }
    /**
     * Удаление предмета
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiReferencesSubjectcrudDelete({
        id,
    }: {
        /**
         * Идентификатор предмета
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subjects/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Предмет с таким ID не найден`,
            },
        });
    }
    /**
     * Обновление предмета
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiReferencesSubjectcrudPatch({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор предмета
         */
        id: number,
        /**
         * Предмет
         */
        requestBody?: SubjectSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/subjects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                404: `Предмет с таким ID не найден`,
            },
        });
    }
}
