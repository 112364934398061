/** делает первый символ текста заглавным */
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/* Плюрализация для русского языка */
export const p11n = (number: number, one: string, two: string, five: string): string => {
  let n: number = Math.abs(number)

  n %= 100
  if (n >= 5 && n <= 20) {
    return five
  }

  n %= 10
  if (n === 1) {
    return one
  }

  if (n >= 2 && n <= 4) {
    return two
  }

  return five
}

export const setLimitString = (
  string: string | undefined,
  limitSymbols: number
): string => {
  if (!string) {
    return ''
  }

  return string.length >= limitSymbols ? `${string.slice(0, limitSymbols)}...` : string
}

/** Переводит латинские символы в кириллические */
export const en2ru = (string: string): string => {
  const en = "qwertyuiop[]asdfghjkl;'zxcvbnm,."
  const ru = 'йцукенгшщзхъфывапролджэячсмитьбю'

  for (let i = 0; i < en.length; i++) {
    // eslint-disable-next-line no-param-reassign
    string = string.split(en.substr(i, 1)).join(ru.substr(i, 1))
  }

  return string
}

/** Проверяет строку на содержание в ней email адреса */
export const isEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

/** Возвращает урл с хостом для изображений из апи */
export const getApiImageUrl = (url?: string): string => {
  return url ? `https://www.ucheba.ru${url}` : ''
}

/* Разбивает секунды в минуты:секунды */
export const splitSecondsByMinutes = (seconds: number | string): string => {
  return [
    Math.floor(Number(seconds) / 60)
      .toString()
      .padStart(2, '0'),
    (Number(seconds) % 60).toString().padStart(2, '0'),
  ].join(':')
}

/** Вместо \n подставляет </br> */
export const setBrs = (text: string): string => {
  const jsonText = JSON.stringify(text)

  const htmlText = jsonText.replace(/\\n/gm, '</br>')

  return JSON.parse(htmlText)
}


export function highlightText(start: number, end: number, textBlock: Element) {
  // Создаем объект Range
  const range = document.createRange();

  // Получаем текстовые узлы внутри блока
  // @ts-ignore
  const walker = document.createTreeWalker(textBlock, NodeFilter.SHOW_TEXT, null, false);

  let currentNode = walker.nextNode();
  let textStartPos = 0;
  let startNode, endNode;

  // Ищем начальную позицию
  while (currentNode) {
    // @ts-ignore
    const nodeLength = currentNode.length;
    if (textStartPos + nodeLength >= start && !startNode) {
      startNode = currentNode;
      range.setStart(startNode, start - textStartPos); // Устанавливаем начало выделения
    }
    if (textStartPos + nodeLength >= end && !endNode) {
      endNode = currentNode;
      range.setEnd(endNode, end - textStartPos); // Устанавливаем конец выделения
    }

    textStartPos += nodeLength;
    currentNode = walker.nextNode();
    if (startNode && endNode) break; // Останавливаем, когда оба узла найдены
  }

  // Получаем объект выделения
  const selection = window.getSelection();

  // Очищаем текущее выделение
  // @ts-ignore
  selection.removeAllRanges();

  // Добавляем новый диапазон
  // @ts-ignore
  selection.addRange(range);
}