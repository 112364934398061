import React, {useContext, useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import {ProfileContext} from "../../App";
import getToken from "../../helpers/getToken";
import {IProfileUcheba} from "../../helpers/types/IProfileUcheba";

interface ITgAlertProviderProps {
    children: any
    open?: boolean
}

const TgAlertProvider: React.FC<ITgAlertProviderProps> = (props) => {
    const {children} = props
    const [open, setOpen] = useState(false)
    const profile = useContext(ProfileContext)
    const [uchebaProfile, setProfile] = useState<IProfileUcheba | null>(null)

    useEffect(() => {
        const token = getToken()

        fetch(`${process.env.REACT_APP_API_UCHEBA_URL}/profile`, {
            headers: {
                'x-auth-token': token
            }
        }).then((res) => {
            return res.json()
        }).then((data: IProfileUcheba) => {
            setProfile(data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const messangers = useMemo(() => {
        if (uchebaProfile) {
            return uchebaProfile.messengers
        }

        return null
    }, [uchebaProfile])

    useEffect(() => {
        if (profile?.cabinet?.id !== 1) {
            if (messangers) {
                const hasTg = messangers.some((el) => el.messengerType === 'telegram')

                if (!hasTg) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
            }
        }
    }, [messangers, profile])

    const href = useMemo(() => {
        return 'https://www.ucheba.ru/events/tg-subscribe/registration?templateId=10118'
    }, [])

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        }}>
            <div style={{
                minHeight: '100%'
            }}>
                {children}
            </div>

            <div
                style={{
                    marginTop: '24px',
                    paddingBottom: '24px',
                    marginBottom: '-24px',
                    display: open ? 'block' : 'none',
                    width: '100%',
                    position: 'sticky',
                    backgroundColor: '#F5F7F9',
                    bottom: '0',
                    zIndex: '1',
                }}
            >
                <a target={'_blank'} href={href} style={{textDecoration: 'none'}}>
                    <Box
                        bgcolor={'rgba(29, 138, 254, 1)'}
                        padding={'16px'}
                        borderRadius={'8px'}
                        position={'relative'}
                        zIndex={10}
                        onClick={() => {
                            setOpen(false)
                        }}
                        sx={{
                            boxShadow: '0px 6px 7px -4px rgba(19, 25, 32, 0.08), 0px 11px 15px 1px rgba(19, 25, 32, 0.04), 0px 4px 20px 3px rgba(19, 25, 32, 0.08)'
                        }}
                    >
                        <Grid container justifyContent={'space-between'}>
                            <Grid item alignItems={'center'} display={'flex'}>
                                <Typography color={'white'} fontSize={'18px'} fontWeight={500} lineHeight={'24px'}>
                                    Подключить уведомления о начале занятий, выполненных работах и других важных событиях
                                </Typography>
                            </Grid>

                            <Grid item alignItems={'center'} display={'flex'}>
                                <TelegramIcon sx={{color: 'white'}} />
                            </Grid>
                        </Grid>
                    </Box>
                </a>
            </div>
        </div>
    )
}

export default React.memo(TgAlertProvider)