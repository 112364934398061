export default {
  'keyboard.tooltip.functions': 'Функции',
  'keyboard.tooltip.symbols': 'Символы',
  'keyboard.tooltip.greek': 'Греческие буквы',
  'keyboard.tooltip.command': 'Режим командной строки Латекс',
  'keyboard.tooltip.numeric': 'числовой',
  'keyboard.tooltip.alphabetic': 'Латинские буквы',
  'tooltip.copy to clipboard': 'Скопировать в буфер обмена',
  'tooltip.redo': 'переделывать',
  'tooltip.toggle virtual keyboard': 'Переключить виртуальную клавиатуру',
  'tooltip.undo': 'расстегивать',
  'menu.insert matrix': 'Вставить матрицу',
  'menu.insert vector': 'Вставить вектор',
  'submenu.array.matrix delimiters': 'Матричные разделители',
  'menu.array.add row above': 'Добавить строку после',
  'menu.array.add row below': 'Добавить строку перед',
  'menu.array.add column after': 'Добавить столбец после',
  'menu.array.add column before': 'Добавить столбец перед',
  'menu.array.delete row': 'Удалить строку',
  'menu.array.delete rows': 'Удалить выбранные строки',
  'menu.array.delete column': 'Удалить столбец',
  'menu.array.delete columns': 'Удалить выбранные столбцы',
  'submenu.array.insert separator': 'Вставить разделитель',
  'menu.insert table': 'Вставить таблицу',
  'submenu.table style': 'Табличный стиль',

  'tooltip.cut to clipboard': ' Вырезать в буфер обмена',
  'tooltip.paste from clipboard': 'Вставить из буфера обмена',
  'tooltip.menu': 'Меню',
  'menu.borders': 'Границы',

  'menu.mode': 'Режим',
  'menu.mode-math': 'Математика',
  'menu.mode-text': 'Текст',
  'menu.mode-latex': 'LaTeX',

  'tooltip.blackboard': 'Достка',
  'tooltip.bold': 'Жирный',
  'tooltip.italic': 'Курсив',
  'tooltip.fraktur': 'Fraktur',
  'tooltip.script': 'Script',
  'tooltip.caligraphic': 'Каллиграфия',
  'tooltip.typewriter': 'Typewriter',
  'tooltip.roman-upright': 'Roman Upright',

  'tooltip.row-by-col': '%@ × %@',

  'menu.font-style': 'Стиль шрифта',
  'menu.accent': 'Акцент',
  'menu.decoration': 'Декорация',
  'menu.color': 'Цвет',
  'menu.background-color': 'Цвет фона',
  'menu.evaluate': 'Оценить',
  'menu.simplify': 'Упростить',
  'menu.solve': 'Решить',
  'menu.solve-for': 'Решить для %@',

  'menu.cut': 'Вырезать',
  'menu.copy': 'Копировать',
  'menu.copy-as-latex': 'Копировать как LaTeX',
  'menu.copy-as-ascii-math': 'Копировать как ASCII Math',
  'menu.copy-as-mathml': 'Копировать как MathML',
  'menu.paste': 'Вставить',
  'menu.select-all': 'Выделить всё',

  // Colors (accessible labels in color swatches)
  'color.red': 'Red',
  'color.orange': 'Orange',
  'color.yellow': 'Yellow',
  'color.lime': 'Lime',
  'color.green': 'Green',
  'color.teal': 'Teal',
  'color.cyan': 'Cyan',
  'color.blue': 'Blue',
  'color.indigo': 'Indigo',
  'color.purple': 'Purple',
  'color.magenta': 'Magenta',
  'color.black': 'Black',
  'color.dark-grey': 'Dark Grey',
  'color.grey': 'Grey',
  'color.light-grey': 'Light Grey',
  'color.white': 'White',
}